import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <p>It can be hard to tell from an ad whether the information is accurate or if the persuasion strategies are potentially misleading.</p>

          <p>One way that people can try to make sense of information is by practicing critical thinking.</p>


          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_3_step_4.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p><strong className="font-bold">Critical thinking</strong> is a skill that allows you to make logical and informed decisions to the best of your ability. It involves analyzing an issue or situation using facts, data, and evidence—and not opinions or feelings.</p>

          <p>People who are using their critical thinking skills question the information they read. They may ask questions such as these:</p>

          <Unordered>
            <li>What did they say?</li>
            <li>Who said it?</li>
            <li>Why are they telling me this?</li>
            <li>Where did they say it?</li>
            <li>When did they say it?</li>
            <li>How did they say it?</li>
            <li>Do their words support their main idea?</li>
          </Unordered>

          <p>Click on the link below to read an article that appeared in the <span className="italic">New York Times</span>. This article presents information analyzing juice cleanses in a critical way. Can you find examples of critical thinking in this article? Record your ideas in your science notebook.</p>

          <Button
            action="secondary"
            color="indigo"
            href="https://www.nytimes.com/2016/04/21/health/juice-cleanse-toxin-misconception.html?_r=0"
            icon="external"
            title="Does juice cleanse the body?"
          >
            Does juice cleanse the body?
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
